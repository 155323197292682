<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <!--<vuexy-logo />-->
        <!--fluid-->
        <b-img
                style="max-width: 200px"
                :src="$root.appLogo"
                alt="Login V2"
        />
        <!--<h2 class="brand-text text-primary ml-1">-->
          <!--{{$root.appName}}-->
        <!--</h2>-->
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >

          <!-- form -->
          <validation-observer
            ref="loginForm"
            #default="{invalid, errors}"
          >
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent="login"
            >

              <b-form-group
                :label="$t('label_login')+ ' / Email'"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('label_login')+ ' / Email'"
                  vid="email"
                  rules="required"
                >
                  <b-form-input
                    v-model="userName"
                    :state="errors.length > 0 ? false:null"
                    name="login-email"
                    :placeholder="$t('label_login')+ ' / Email'"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">{{$t('label_password')}}</label>
                  <b-link tabindex="-1" :to="{name:'auth-forgot-password'}">
                    <small>{{$t('title_password_forgot')}}?</small>
                  </b-link>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="Password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-button
                type="submit"
                variant="primary"
                block
                :disabled="invalid"
              >
                {{$t('label_log_in')}}
              </b-button>
            </b-form>
          </validation-observer>


        </b-col>
      </b-col>

    </b-row>
  </div>
</template>

<script>


import { ValidationProvider, ValidationObserver } from 'vee-validate'

import {
  BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton, BAlert, VBTooltip,
} from 'bootstrap-vue'

import useJwt, {getFingerprint} from '@/auth/jwt/useJwt'
import { required} from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'

import { initUserAbility } from '@/libs/acl/config'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {

    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      userName: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      fingerprint: ''
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    login() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {

            this.async('post','/auth/login', {
                userName:this.userName,
                userPassword:this.password,
                fingerprint:this.fingerprint,

            },response => {

                const { userData, accessToken, refreshToken} = response.data;

                if(!userData || !accessToken || !refreshToken){
                    return this.$refs.loginForm.setErrors('Wrong data response')
                }

                useJwt.setToken(accessToken);
                useJwt.setRefreshToken(refreshToken);
                useJwt.setRefreshUpdatingInterval();

                let userDataJson = JSON.stringify(userData);
                localStorage.setItem('userData', userDataJson);
                sessionStorage.setItem('userData', userDataJson);

                switch (userData.language) {
                    case '2':
                        this.$locale = 'pl';
                        break;
                    default:
                        this.$locale = 'en';
                }

                this.$store.dispatch('app/UPDATE_LOCALE', {locale: this.$locale}).then(()=>{
                    let curRouterTitle = this.$router.currentRoute.meta.title;
                    if(curRouterTitle){
                        document.title = this.$t(curRouterTitle) || localStorage.getItem('app_name');
                    }
                });

                this.$store.dispatch('app/SET_CONFIG');

                this.$ability.update(initUserAbility(userData));
                // console.log('ability',this.$ability);
                this.$router.replace(getHomeRouteForLoggedInUser(userData.role))
                    .then(() => {
                        this.showToast('info', this.$t('label_hi')+' ' + this.authUser().name, this.$t('label_successfully_logged_in'));
                    });
            })

        }
      })
    },
  },
    mounted(){
        this.$refs.loginForm.setErrors('Wrong data response')
    },
    created(){
        getFingerprint().then((res)=>{this.fingerprint = res;});
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
